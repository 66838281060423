import React from 'react';

import { Box } from '@core';
import { Text } from '@components';
import { CTASection } from '@containers';
import footerImageMobile from '@images/product-page/common-footer-image-mobile.png';
import footerImageMobileTop from '@images/product-page/common-footer-image-mobile-top.png';
import footerImageTablet from '@images/product-page/common-footer-image-tablet.png';
import footerImageTabletTop from '@images/product-page/common-footer-image-tablet-top.png';
import footerImage from '@images/product-page/common-footer-image.png';

const FooterCta = ({ padding, buttons = [], ...props }) => (
  <Box {...padding}>
    <CTASection
      data={{
        text: ({ discountPercentage, t }) => ({
          ...props,
          buttons: buttons.map((button) => ({
            ...button,
            subtitle:
              button?.buttonProps?.discountSubtitle &&
              t('common:actions.nowDiscountOff', { discountPercentage }),
          })),
        }),
        image: () => ({
          mobile: {
            src: footerImageMobile,
            alt: 'Atlas footer',
          },
          mobileTop: {
            src: footerImageMobileTop,
            alt: 'Atlas footer',
          },
          tablet: {
            src: footerImageTablet,
            alt: 'Atlas footer',
          },
          tabletTop: {
            src: footerImageTabletTop,
            alt: 'Atlas footer',
          },
          desktop: {
            src: footerImage,
            alt: 'Atlas footer',
            position: {
              bottom: 0,
              height: 540,
              maxWidth: 3000,
            },
          },
        }),
      }}
      showAvailablePlatforms
      titleComponent={Text.Heading2}
      sectionProps={{
        wrapperProps: { pt: 0, pb: 0 },
      }}
      wrapperProps={{
        backgroundColor: '#BFE2FF',
      }}
      contentProps={{
        pt: 160,
        pb: 80,
      }}
    />
  </Box>
);

export default FooterCta;
